// extracted by mini-css-extract-plugin
export var body = "memberShip-module--body--HFK4E";
export var card = "memberShip-module--card--Cj3ju";
export var commaList = "memberShip-module--commaList--uaIow";
export var container = "memberShip-module--container--VWf69";
export var description = "memberShip-module--description--yvOw1";
export var footer = "memberShip-module--footer--YNr3o";
export var head = "memberShip-module--head--CEJGw";
export var imageBackground = "memberShip-module--imageBackground--PlCIC";
export var logo = "memberShip-module--logo--2h4Fh";
export var logoContainer = "memberShip-module--logoContainer--TMRqV";
export var logoImage = "memberShip-module--logoImage--pcOhq";
export var membership = "memberShip-module--membership--t4wGK";
export var name = "memberShip-module--name--uiM2d";
export var role = "memberShip-module--role--dDgyn";